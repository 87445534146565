<template>
  <div class="settings">
    <van-nav-bar
      :title="$t('auth.account.title')"
      fixed
      placeholder
    >
      <template #left>
        <van-icon
          :name="$icon('back')"
          size="16"
          color="#000"
          is-link
          @click="$router.back()"
        />
      </template>
    </van-nav-bar>

    <field-list
      :title="$t('auth.updatePassword.title')"
      class="container"
    >
      <van-form @submit="updatePassword">
        <van-field
          v-model="currentPassword"
          type="password"
          name="currentPassword"
          :label="$t('auth.updatePassword.currentPassword')"
          placeholder="********"
          :rules="[{ required: true, message: $t('auth.validation.password.currentRequired') }]"
        />
        <van-field
          v-model="newPassword"
          type="password"
          name="newPassword"
          :label="$t('auth.updatePassword.newPassword')"
          placeholder="********"
          :rules="[{ required: true, message: $t('auth.validation.password.required') }]"
        />
        <van-field
          v-model="repeatedNewPassword"
          name="repeatedNewPassword"
          type="password"
          :label="$t('auth.updatePassword.newPasswordRepeated')"
          placeholder="********"
          :rules="[
            { required: true, message: $t('auth.validation.password.repeatedRequired') },
            { validator: passwordsMatch, message: $t('auth.validation.password.mismatch') },
          ]"
        />
        <van-button
          type="primary"
          native-type="submit"
          block
          round
        >
          {{ $t('auth.updatePassword.submit') }}
        </van-button>
      </van-form>
    </field-list>
  </div>
</template>

<script>
import { Notify } from 'vant';
import FieldList from '@/components/FieldList';
import { auth } from '@/firebase';

export default {
  components: {
    FieldList,
  },

  data() {
    return {
      user: {},
      currentPassword: '',
      newPassword: '',
      repeatedNewPassword: '',
    };
  },
  mounted() {
    this.user = auth.currentUser;
  },

  methods: {
    updatePassword(values) {
      auth.signInWithEmailAndPassword(this.user.email, values.currentPassword)
        .then((result) => {
          result.user.updatePassword(values.repeatedNewPassword)
            .then(() => {
              Notify({
                type: 'success',
                message: this.i18n.t('auth.updatePassword.success'),
              });
              this.currentPassword = '';
              this.newPassword = '';
              this.repeatedNewPassword = '';
            });
        })
        .catch((error) => {
          if (error.code === 'auth/wrong-password') {
            Notify({
              type: 'warning',
              message: error.message,
            });
          }
        });
    },
    passwordsMatch(value) {
      return value === this.newPassword;
    },
  },
};
</script>

<style lang="scss">
/* .settings {
} */
</style>
